import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import {mapValues} from 'lodash-es'
import useLang from '../hooks/useLang'
import AppShell from '../components/containers/AppShell'
import CookiesContainer from '../components/molecules/Cookies/CookiesContainer'
import CookiesContent from '../components/molecules/Cookies'
import SectionCallToAction from '../components/molecules/SectionCallToAction'
import Navbar from '../components/containers/Navbar'
import Typography from '../components/atoms/Typography'


const Cookies = ({data, pageContext: {lang: pageLang}}) => {
  const langToSlug = mapValues(data?.markdownRemark.frontmatter, 'slug')
  const {lang} = useLang(pageLang, langToSlug)

  const pageData = data.markdownRemark.frontmatter[lang]

  return (
    <AppShell title={pageData.menuText} lang={lang} langToSlug={langToSlug} seo={pageData.seo}>
      <Navbar title={pageData.menuText} lang={lang} langToSlug={langToSlug} />
      <main>
        <CookiesContainer>
          <Typography variant="h2" type="h1" isHeading gutter>{pageData.title}</Typography>
          <Typography variant="h3" type="h3" color="primary" gutter>{pageData.subtitle}</Typography>
          <CookiesContent>{pageData.description}</CookiesContent>
        </CookiesContainer>
        <SectionCallToAction
            bottom
            link={pageData.callToAction.action.link}
            linkText={pageData.callToAction.action.text}
        >
          {pageData.callToAction.title}
        </SectionCallToAction>
      </main>
    </AppShell>
  )
}

export const data = graphql`
  query Cookies($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        ...CookiesPageFragment
      }
    }
  }
`

Cookies.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    lang: PropTypes.string.isRequired,
  }).isRequired,
}

export default Cookies
